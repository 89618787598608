<template>
  <div id="RegisterForm" class="wrap" v-if="!isLoadingNow">
    <div class="formWrap" v-if="!hasSend">
      <h1 class="title">ユーザー情報の入力</h1>
      <b-form>
        <div class="inputBox">
          <v-form-name
            label="氏名"
            :left.sync="inputData.sei"
            :right.sync="inputData.mei"
            :placeholders="{left: '例）山田' , right: '例）太郎'}"
            :errors="{left: errors.sei || '', right: errors.mei || ''}"
          >
          </v-form-name>
          <v-form-name
            label="フリガナ"
            :left.sync="inputData.kanaSei"
            :right.sync="inputData.kanaMei"
            :placeholders="{left: '例）ヤマダ' , right: '例）タロウ'}"
            :errors="{left: errors.kanaSei || '', right: errors.kanaMei || ''}"
          >
          </v-form-name>
          <v-form-barth
            label="生年月日"
            name="barth"
            :placeholders="{year: '例）1980', month: '例）10', day: '例）10'}"
            :errMessage="errors.barth || []"
            v-model="inputData.barth">
          </v-form-barth>
          <div>
            <b-row class="textBase">
              <b-col sm="4" class="labelWrap">
                <label>性別</label>
              </b-col>
              <b-col sm="5" class="pl-20 textData">
                <b-form-select
                    v-model="inputData.gender"
                    :class="[errors.gender ? 'errItem' : '', 'gender']"
                    :options="genderList"
                  />
              </b-col>
            </b-row>
            <b-row
              v-for="(msg, index) in errors.gender" :key="'gender' + index">
              <div class="label pl-0 pr-1 mb-0"></div>
              <div class="pl-5">
                <p class="error my-1">{{msg}}</p>
              </div>
            </b-row>
            <span class="genderComm">* 選択は任意です。未選択とすることも可能です。</span>
          </div>

          <b-row class="textBase">
            <b-col sm="4" class="labelWrap">
              <label>メールアドレス<br>(ログインID)</label>
            </b-col>
            <b-col sm="5" class="textData">
              {{ mailAdress }}
            </b-col>
          </b-row>
          <span class="guide">
            英大文字・英小文字・数字・記号をそれぞれ1つ以上利用し8文字以上入力してください。
          </span>
          <v-form-text
            label="パスワード"
            type="password"
            itemStyle="line"
            inputClass="w-long"
            :errMessage="errors.password || []"
            v-model="inputData.password"
            maxLength="255"
            @check = 'passCheck'
          >
          </v-form-text>
          <v-form-text
            label="パスワードの確認"
            type="password"
            itemStyle="line"
            inputClass="w-long"
            :errMessage="errors.passwordConf || []"
            v-model="inputData.passwordConf"
            maxLength="255"
            @check = 'passCheck'
          >
          </v-form-text>
        </div>
        <div class="approvalWrap">
          <b-link v-b-modal.PrivacyPolicyShow>助成金業務支援システムの個人情報の取扱について</b-link>
          <b-form-checkbox
            v-model="inputData.approval"
            value="1"
            class="approvalBox"
            id="approval"
            size="lg"
          >
            <span>個人情報の取扱について同意します</span>
          </b-form-checkbox>
        </div>
        <b-alert
        class="errorWrap"
        :show="typeof errors.errorMessage !== 'undefined'"
        variant="warning">{{errors.errorMessage}}</b-alert>
        <b-button
          class="regist"
          :disabled="(!inputData.approval || hasPassErr)"
          @click="send"
        >
          登録
        </b-button>
      </b-form>
    </div>
    <div class="end" v-if="hasSend">
      <h1>登録が完了しました</h1>
      <b-button
        class="btn fw-6"
        @click="$router.push({ name: 'gasLogin' })"
      >
        ログイン画面へ
      </b-button>
    </div>

    <!-- popUp -->
    <v-pop-privacy-policy></v-pop-privacy-policy>
  </div>
</template>

<script>
import privacyPolicyModal from '@/components/parts/PrivacyPolicyModal.vue';
import api from '@/modules/api';
import CONST_OPTIONS from '@/constants/options';

export default {
  name: 'RegisterForm',
  components: {
    'v-pop-privacy-policy': privacyPolicyModal,
  },
  data() {
    return {
      mailAdress: null,
      inputData: {
        sei: null,
        mei: null,
        kanaSei: null,
        kanaMei: null,
        barth: null,
        gender: null,
        approval: null,
        password: null,
        passwordConf: null,
      },
      errors: [],
      isLoadingNow: true,
      hasSend: false,
      hasPassErr: false,
    };
  },
  async created() {
    this.$store.dispatch('page/onWaiting');
    const { uuid } = this.$route.params;
    const params = { uuid };
    const res = await api.send('/api/user/register/getMailAddress', params)
      .catch((error) => {
        console.log(error);
        this.$router.push({ name: 'gasLogin' });
        return false;
      });
    if (res !== false) {
      this.mailAdress = res.data.mailAddress;
      this.isLoadingNow = false;
    }
    this.$store.dispatch('page/offWaiting');
  },
  methods: {
    async send() {
      this.$store.dispatch('page/onWaiting');
      const { uuid } = this.$route.params;
      const params = Object.assign(this.inputData, { uuid });
      const res = await api.send('/api/user/register/store', params)
        .catch((error) => {
          this.errors = error.response.data.message;
          return false;
        });
      if (res !== false) {
        this.hasSend = true;
      }
      this.$store.dispatch('page/offWaiting');
    },
    passCheck() {
      this.$delete(this.errors, 'passwordConf');
      this.hasPassErr = false;
      if (!this.inputData.password || !this.inputData.passwordConf) {
        return true;
      }
      if (this.inputData.password !== this.inputData.passwordConf) {
        this.$set(this.errors, 'passwordConf', ['パスワードの確認とパスワードが一致するよう入力してください。']);
        this.hasPassErr = true;
        return false;
      }
      return true;
    },
  },
  computed: {
    genderList() {
      return CONST_OPTIONS.GENDER_TYPE_OPTION;
    },
  },
};
</script>


<style scoped>
.gender{
  width: 150px;
  height: 50px;
}
.title{
  margin-top: 112px;
  margin-bottom: 37px;
  color: #333333;
  font-weight: 600;
  font-size: 24px;
}
.formWrap{
  text-align: center;
}
.inputBox{
  width: 700px;
  margin: 0 auto;
  padding-bottom: 27px;
  border-bottom: solid 1px #CCCCCC;
}
.textBase{
  text-align: left;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  align-items: center;
  margin:15px auto;
}
.textBase label,
.textBase .labelWrap {
  width: 237px;
  max-width: 237px;
  font-weight: bold;
}
.textBase label{
  padding-left:0.5em;
  text-indent:-0.5em;
  margin-bottom:0;
}
.textBase .labelWrap {
  margin-right: 0;
  padding-right: 0;
  text-align: left;
}
.textBase .textData{
  padding-left: 20px;
}
.approvalWrap{
  margin-top:30px;
}
.approvalWrap a::after{
  content: "";
  display: block;
  border-bottom: solid 1px #0A8EA7;
  width: 370px;
  margin:auto;
}
.approvalWrap a:hover {
  text-decoration: none;
}
.approvalBox{
  margin:30px 0;
}
.approvalWrap label span{
  display: block;
  margin-top: 3px;
}
.regist,
.end button.btn{
  background-color: #122889!important;
  color: #FFFFFF!important;
  font-size: 18px;
  font-weight: 600;
  margin: 10px;
}
/* 完了画面 */
.end{
  width: 280px;
  margin:auto;
  margin-top: 32.8vh;
  text-align: center;
}
.end h1{
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 46px;
}
span.guide{
  margin-left: 271px;
  display: block;
  text-align: left;
  width: 400px;
  font-size: 13px;
  margin-bottom: -11px;
}
span.genderComm {
    margin-left: 271px;
    display: block;
    text-align: left;
    width: 400px;
    font-size: 13px;
    margin-top: -11px;
}
</style>
<style>
  #RegisterForm .custom-control-label::before{
    border-radius: 0!important;
    border: solid 2px #6E6E6E!important;
  }
  #RegisterForm .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #122889;
  }
  #RegisterForm .errorWrap{
    width: 700px;
    margin:0 auto;
    margin-bottom: 30px;
  }
</style>
