<template>
  <b-modal
    id="PrivacyPolicyShow"
    title-class="titleMsg"
    body-class="modalBox"
    header-class="privacyModalHeader"
    content-class="p-5"
    title="個人情報保護方針"
    size="xl"
    title-tag="h2"
    ok-title = "閉じる"
    ok-variant="secondary"
    :hide-header-close="true"
    :centered="true"
    :ok-only="true"
  >
    <div class="title-message">
      住友電工グループ社会貢献基金は国内外の様々な分野における人材育成と学術振興を行うことによる社会貢献を目的とする公益財団法人です。<br>
      本財団の取得する個人情報はこの事業目的に沿って使用するもので、本財団は「個人情報 の保護に関する法律」に基づき、
      個人情報に関して適用される法令およびその精神を尊重、遵守し、個人情報を適切かつ安全に取扱うとともに個人情報の保護に 努めます。
    </div>
    <div class="privacyPolicy">
      <p class="headMsg">1. 個人情報の取得</p>
      <span class="pl-20">
        本財団は、個人情報取得の際、その利用目的を明らかにし、本人の意思で提供された情報を取扱います。
      </span>
      <p class="headMsg">2. 利用目的および保護</p>
      <span class="pl-20">
        本財団が取扱う個人情報は、その利用目的の範囲内でのみ利用します。<br>
        また、法令等の定めに基づく場合や、人の生命、身体または財産の保護のために必要とする場合をのぞいて、
        個人情報を第三者へ提供することは致しません。
      </span>
      <p class="headMsg">3. 管理体制</p>
      <span>
        <ol>
          <li>
            全ての個人情報は、不正アクセス、盗難、持出し等による紛失、破壊、改ざん及び漏洩等が発生しないように適正に管理し、
            必要な予防・是正措置を講じます。
          </li>
          <li>
            個人情報の本人による開示・訂正・利用停止等の取扱いに関する問合わせは、随時受付け、適切かつ速やかに対応いたします。
          </li>
        </ol>
      </span>
      <p class="headMsg">4. 法令遵守のための取組みの維持と継続</p>
      <span class="pl-20">
        本財団は、個人情報保護に関する法令およびその他の規則に則った業務運営に努めて参ります。
      </span>
    </div>
  </b-modal>

</template>
<script>

export default {
  name: 'PrivacyPolicyModal',
};
</script>
<style>
.modalBox{
  padding: 25px;
}
.privacyModalHeader {
  border-bottom-color: #A0A9D0 !important;
  padding: 0 !important;
}
</style>
<style scoped>

.titleMsg {
  font-size: 26px !important;
}
.headMsg {
  font-size: 24px !important;
}
.privacyPolicy {
  margin-top: 20px;
}
.privacyPolicy p{
  font-size: 28px;
  margin:5px;
  display: block;
}
.title-message, .privacyPolicy span{
  display: block;
  font-size: 16px;
}
.privacyPolicy span{
  margin:10px 0;
}
.pl-20{
  padding-left: 20px;
}
</style>
