import { render, staticRenderFns } from "./RegisterForm.vue?vue&type=template&id=560c783a&scoped=true"
import script from "./RegisterForm.vue?vue&type=script&lang=js"
export * from "./RegisterForm.vue?vue&type=script&lang=js"
import style0 from "./RegisterForm.vue?vue&type=style&index=0&id=560c783a&prod&scoped=true&lang=css"
import style1 from "./RegisterForm.vue?vue&type=style&index=1&id=560c783a&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "560c783a",
  null
  
)

export default component.exports